import { Show } from "solid-js";
import type { ComponentProps, JSXElement, FlowComponent } from "solid-js";
import { Collapsible as KCollapsible } from "@kobalte/core";
import { Icon } from "solid-heroicons";
import { chevronDown } from "solid-heroicons/outline";

import "./collapsible.css";

type KCollapsibleProps = ComponentProps<typeof KCollapsible.Root>;

type CollapsibleProps = Pick<KCollapsibleProps, "defaultOpen"> & {
  trigger?: string | JSXElement;
  footer?: string | JSXElement;
  name?: string;
  id?: string;
  rootClass?: string;
  triggerClass?: string;
  contentClass?: string;
};

export const Collapsible: FlowComponent<CollapsibleProps> = (props) => {
  return (
    <KCollapsible.Root
      id={props.name ?? props.id}
      defaultOpen={props.defaultOpen}
      class="collapsible"
      classList={{ [`${props.rootClass}`]: !!props.rootClass }}
    >
      <KCollapsible.Trigger
        class="collapsible__trigger"
        classList={{ [`${props.triggerClass}`]: !!props.triggerClass }}
      >
        <div>{props.trigger}</div>
        <Icon
          path={chevronDown}
          class="w-4 h-4 collapsible__trigger-icon"
          width={16}
          height={16}
        />
      </KCollapsible.Trigger>
      <KCollapsible.Content
        class="collapsible__content"
        classList={{ [`${props.contentClass}`]: !!props.contentClass }}
      >
        {props.children}
      </KCollapsible.Content>
      <Show when={props.footer}>{props.footer}</Show>
    </KCollapsible.Root>
  );
};
